<template>
  <WeContainer
    header="true"
    v-bind:bread-crumb="category.breadCrumb"
    v-bind:actions="containerActions"
    v-if="ready"
  >
    <WeTable
      v-bind:draggable="true"
      v-bind:index="false"
      v-bind:data.sync="category.list"
      v-bind:columns="columns"
      v-bind:actions="tableActions"
      v-bind:multiLanguage="true"
      v-on:on-action="onAction"
      v-on:on-switch="onSwitch"
      v-on:order-changed="onOrderChange"
      v-on:FilterLanguage="FilterLanguage"
    ></WeTable>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "List",
  data() {
    return {
      ready: false,
      tableActions: [
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger",
          childrenText: "Alt Kategori",
          action: "children",
        },
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary ml-2",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],
      columns: [
        { name: "image", icon: "fas fa-camera", type: "image", width: "5%" },
        { name: "name", th: "Kategori Adı", type: "string" },
        {
          name: "marketplace_list",
          th: "Pazaryeri",
          type: "marketplace_list",
        },
        {
          name: "products_count",
          th: "Aktif/Pasif Ürün Adeti",
          type: "split_count",
          width: "10%",
        },
        {
          name: "is_showcase",
          th: "Vitrinde Göster",
          type: "boolean",
          width: "10%",
        },
        {
          name: "is_showmenu",
          th: "Menüde Göster",
          type: "boolean",
          width: "10%",
        },
        { name: "is_active", th: "Durum", type: "boolean", width: "10%" }
      ],
    };
  },
  methods: {
    ...mapActions("category", [
      "getList",
      "delete",
      "isActive",
      "showCase",
      "showMenu",
      "orderUpdate",
    ]),
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data.row, data.index);
      } else if (data.key == "edit") {
        this.onEdit(data.row);
      } else if (data.key == "children") {
        this.onChildren(data.row);
      }
    },
    onChildren(row) {
      const parent = {
        id: row.id,
        name: row.name,
      };
      this.category.breadCrumb.push(parent);

      this.$router.replace("/categories/children/" + row.id);
    },
    onEdit(row) {
      this.$router.replace("/categories/detail/" + row.id);
    },
    onDelete(row, index) {
      const childElement = document.getElementById("delete-child");
      let message = `${row.name} kategorisini silmek istediğinize emin misiniz ?`;
      if (row.childCount) {
        message += `<div class="d-block mb-2">
                        <input type="checkbox" id="delete-child"/><label for="delete-child" class="font-14 ml-1 mb-0">Alt Kategorileri de Sil</label>
                      </div>`;
      }
      this.$swal({
        title: message,
        html: `Bu işlemi <b class="text-danger">Çöp Kutusu</b> bölümünden geri alabilirsiniz.`,
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const checkPermission = this.checkPermission("d");
          if (!checkPermission) {
            permission.alert(this);
          } else {
            this.delete({
              id: row.id,
              delete_child: childElement ? childElement.checked : false,
              onSuccess: (result) => {
                if (result && result.data && result.data.status !== "error") {
                  this.$toast.success("Kategori Başarıyla Silindi");
                } else {
                  this.$swal({
                    title: "Kategori Silinemedi",
                    text: result.data.message,
                    icon: "error",
                    confirmButtonText: "Tamam",
                  });
                }
              },
            });
          }
        }
      });
    },
    onSwitch(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        if (data.column == "is_showcase") {
          this.showCase({
            form: data,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Vitrin Durumu Güncellendi");
              } else {
                this.$toast.error("Vitrin Durumu Güncellenemedi");
              }
            },
            onError: (error) => {
              this.$toast.error("Vitrin Durumu Güncellenemedi");
            },
          });
        }
        if (data.column == "is_showmenu") {
          this.showMenu({
            form: data,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Menü Durumu Güncellendi");
              } else {
                this.$toast.error("Menü Durumu Güncellenemedi");
              }
            },
            onError: (error) => {
              this.$toast.error("Menü Durumu Güncellenemedi");
            },
          });
        }
        if (data.column == "is_active") {
          this.isActive({
            form: data,
            onSuccess: (result) => {
              if (result && result.data && result.data.status !== "error") {
                this.$toast.success("Durum Güncellendi");
              } else {
                this.$toast.error("Durum Güncellenemedi");
              }
            },
            onError: (error) => {
              this.$toast.error("Durum Güncellenemedi");
            },
          });
        }
      }
    },
    getAll() {
      this.ready = false;
      this.getList({
        id: this.$route.params.id,
        list: 'all',
        onSuccess: (result) => {
          this.ready = true;
        },
      });
    },
    clearBreadCrumb() {
      const id = this.$route.params.id;
      if (!id) {
        this.category.breadCrumb = [];
      } else {
        let breadCrumb = this.category.breadCrumb;
        if (breadCrumb && breadCrumb.length) {
          const lastElement = breadCrumb[breadCrumb.length - 1];
          if (lastElement.id !== id) {
            breadCrumb.pop();
          }
        }
      }
    },
    onOrderChange(data) {
      const checkPermission = this.checkPermission("u");
      if (!checkPermission) {
        permission.alert(this);
      } else {
        this.orderUpdate({
          form: data,
          onSuccess: (result) => {
            this.$toast.success("Kategori Sırası Güncellendi");
            this.category.list = this.category.list.sort(
              (a, b) => a.order_no - b.order_no
            );
          },
          onError: () => {
            this.$toast.error("Kategori Sırası Güncellenemedi");
          },
        });
      }
    },
    checkPermission(code) {
      return permission.check("category", code);
    },
    FilterLanguage(lang) {
      this.getList({
        id: this.$route.params.id,
        lang: lang,
        onSuccess: (result) => {
          this.ready = true;
        },
      });
    },
  },
  computed: {
    ...mapState(["category", "shared","session"]),
    containerActions() {
      let data = [
        {
          text: "Yeni Kategori",
          path: "/categories/new",
        },
        {
          text: "Çöp Kutusu",
          class: "btn btn-outline-danger float-md-right",
          icon: "fas fa-trash",
          path: "categories/trash",
        },
      ];
      if (this.category.breadCrumb && this.category.breadCrumb.length) {
        data = data.splice(0, 1);
      }

      return data;
    },
  },
  mounted() {
    this.getAll();
    this.clearBreadCrumb()
    let locales = this.session?.config["site.available_locales"];
    if(locales != undefined){
      locales = JSON.parse(locales)
      if(locales.length > 1) this.columns.push({ name: "locales", th: "Dil", type: "language"})
    }
  },
};
</script>
